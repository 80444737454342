@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap);
@import 'assets/styles/bootstrap-custom.scss';

@import 'assets/styles/_root.scss';
@import 'assets/styles/fonts.css';
@import 'assets/styles/sidebar.scss';
@import 'assets/styles/back-office-panel.scss';
@import 'src/assets/styles/custom-list.scss';
@import 'assets/styles/website.scss';
@import 'assets/styles/buttons.scss';
@import 'assets/styles/input.scss';
@import 'assets/styles/select.scss';
@import 'assets/styles/checkbox.scss';
@import 'assets/styles/radio-button.scss';
@import 'assets/styles/badges.scss';
@import 'assets/styles/ialerts.scss';
@import 'assets/styles/on-off-toggle.scss';
@import 'assets/styles/typography.scss';
@import 'assets/styles/table.scss';
@import 'assets/styles/scrollbar.scss';
@import 'assets/styles/containers.scss';
@import 'assets/styles/data-lists.scss';
@import 'assets/styles/printables.scss';
@import 'assets/styles/thirdparty-customizations/quill.css';
@import '~@ng-select/ng-select/themes/default.theme.css';

@import '~prismjs/plugins/toolbar/prism-toolbar.css';
@import '~prismjs/themes/prism-okaidia';
@import 'assets/styles/prismjs/line-numbers.css';

@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.custom-attribute {
    background: #f3f380;
    padding: 3px 9px;
    border-radius: 4px;
    margin: 0 3px;
}

.filters-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .filters {
        display: flex;
        align-items: flex-end;

        .cg-input-group {
            margin-right: 5px;
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}

// timepicker
.time-wrapper table tr td {
    .btn.btn-default {
        background: var(--ap-secondary);
        border: 1px solid #777;
        border-radius: 3px;
        height: 40px;
        font-weight: bold;
    }

    a.btn.btn-link {
        display: flex;
        justify-content: center;
    }
}