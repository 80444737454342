* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    list-style: none;
    text-decoration: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    list-style: none;
    text-decoration: none;
}

/* Routing Styling */

.rtnv {
    background: var(--ap-banner);
}

.routing {
    width: 75%;
    margin: 0 auto;
    padding: 50px 6% 30px;
}

.rtTitle {
    text-align: center;
    color: #1d1d1d;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;

    span {
        cursor: pointer;
    }

    span:hover {
        color: var(--ap-primary);
    }
}

.routing form {
    background: #f1f5ff;
    padding: 10px 10px 18px;
    border-radius: 20px;
    box-shadow: 0px 1px 6px 0px rgba(20, 20, 20, 0.75);
    -webkit-box-shadow: 0px 1px 6px 0px rgba(20, 20, 20, 0.75);
    -moz-box-shadow: 0px 1px 6px 0px rgba(20, 20, 20, 0.75);
}

.routeBtn {
    width: 100%;
    text-align: center;
}

.routeBtn button {
    padding: 15px 20px;
    border-radius: 10px;
    border: 1px solid var(--ap-primary);
    background: var(--ap-primary);
    color: #fff;
    transition: all 0.5s;
    font-size: 18px;
    cursor: pointer;
}

.routing .top {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 30px;
}

.routing .top .left {
    color: var(--ap-primary);
}

.routing .top .right a {
    color: var(--ap-primary);
}

.routing form .middle {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.routing form .middle .box {
    flex: 1;
    border: 1px solid #555;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.routing form .middle .box i {
    color: var(--ap-primary);
}

.routing form .middle .box input {
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 16px;
    background: transparent;
}

.routing form .bottom {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 10px;
}

.routing form .bottom input {
    width: 100%;
    border: 1px solid #555;
    padding: 10px;
    border-radius: 10px;
    outline: 0;
    background: transparent;
}

.routing form .bottom select {
    background: transparent;
    width: 100%;
    border: 1px solid #555;
    padding: 10px;
    border-radius: 10px;
    outline: 0;
}

.routing form .bottom button {
    width: 100%;
    background: var(--ap-primary);
    border: 0;
    outline: 0;
    color: #fff;
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
}

.routing .list {
    margin-top: 50px;
}

.routing .list .listTitle {
    color: #333;
    font-weight: 600;
}

.list .box {
    position: relative;
    margin: 50px 0 !important;
    display: flex;
    gap: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #f0f3ff;
    padding: 40px 15px 10px;
    box-shadow: 0px 1px 6px 0px rgba(20, 20, 20, 0.75);
    -webkit-box-shadow: 0px 1px 6px 0px rgba(20, 20, 20, 0.75);
    -moz-box-shadow: 0px 1px 6px 0px rgba(20, 20, 20, 0.75);
}

.list .box .live {
    padding: 5px 16px;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
}

.list .box .line {
    width: 3px;
    height: 90%;
    background: var(--ap-primary);
    position: absolute;
    top: 15px;
    left: 0;
}

.list .box .right .top {
    margin: 0 0 10px;
    display: flex;
    justify-content: space-between;
}

.list .box .right .top .left p {
    color: #000;
}

.list .box .right .top .left p i {
    color: orange;
}

.list .box .right .top .right {
    display: flex;
    gap: 10px;
}

.list .box .right .top .right i {
    color: var(--ap-primary);
}

.list .box .right .middle {
    margin-top: 20px;
}

.list .box .right .middle p {
    font-size: 18px;
    line-height: 28px;
}

.list .box .right .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.list .box .right .bottom .left p {
    margin-bottom: 12px;
}

.list .box .right .bottom .left p i {
    position: relative;
    color: var(--ap-primary);
}

.list .box .right .bottom .left .text .dotted {
    display: none;
}

.list .box .right .bottom .right {
    flex-shrink: 0;
}

.list .box .right .bottom .right button {
    border: 0;
    outline: 0;
    height: fit-content;
    color: #fff;
    font-weight: 600;
    padding: 15px 70px;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 5px !important;

}

/* Responsive Styling */

@media (max-width: 1070px) {
    .routing {
        width: 80%;
    }
}

@media (max-width: 990px) {
    .routing {
        width: 85%;
    }
}

@media (max-width: 910px) {
    .routing {
        width: 90%;
    }
}

@media (max-width: 850px) {
    .routing {
        width: 95%;
    }
}

@media (max-width: 730px) {
    .routing form .middle {
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .list .box .right .top {
        flex-direction: column-reverse;
        gap: 1rem;
    }
}

@media (max-width: 790px) {
    .list .box .right .bottom {
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width: 600px) {
    .routing form .bottom {
        flex-wrap: wrap;
    }
}

@media (max-width: 500px) {
    .list .box {
        flex-direction: column;
    }

    .list .box .bottom .right {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 1024px) {
    .hero .right h1 {
        font-size: 24px;
    }
}